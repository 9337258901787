import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import { Button, Col, Collapse, Image, Row } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import useWindowDimensions from "../../hook/useWindowDimensions";

import { scroller } from "react-scroll";
import { getBlogList } from "../../services/Api/Api";
import { Link } from "react-router-dom";
import { Initial } from "react-initial";
import { truncate } from "lodash";

const { Panel } = Collapse;

function Home() {
  const { width } = useWindowDimensions();
  const [blogListItem, setBlogListItem] = useState([]);

  useEffect(() => {
    const fetchBlogList = async () => {
      const getBlogListItem = await getBlogList();
      console.log("getBlogListItem: ", getBlogListItem);

      if (getBlogListItem?.results?.length <= 0) {
        setBlogListItem([]);
      } else {
        setBlogListItem(getBlogListItem?.results);
      }
    };
    fetchBlogList();
  }, []);

  //scroll function
  function scrollTo() {
    scroller.scrollTo("about-section", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -70,
    });
  }

  const RouteLink = () => {
    window.scrollTo(0, 0);
  };

  const FormatDate = (value) => {
    const date = new Date(value);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <div>
      <div className={styles.home_title_div}>
        {width < 768 ? (
          <div className={styles.main_title}>
            <span>We back</span>
            <span>
              <span className={styles.red_text}>disruptive</span> Web3
            </span>
            <span> companies </span>
            <span>building the next</span>
            <span>
              generation of <span className={styles.red_text}>the </span>
            </span>
            <span>
              <span className={styles.red_text}>internet</span>.
            </span>
          </div>
        ) : (
          <div className={styles.main_title}>
            <span>
              We back <span className={styles.red_text}>disruptive</span> Web3
            </span>
            <span> companies building the next</span>
            <span>
              generation of{" "}
              <span className={styles.red_text}>the internet</span>.
            </span>
          </div>
        )}

        {width < 768 ? (
          <a
            href="https://fyirw2tfakw.typeform.com/to/UX93QZNl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className={styles.invest_btn}>INVEST WITH US</Button>
          </a>
        ) : (
          <div className={styles.downAero_style}>
            <Image
              src="/img/downAero.svg"
              preview={false}
              onClick={() => scrollTo()}
              spy={true}
              smooth={true}
              duration={500}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </div>

      <div style={{ marginTop: "130px" }} className="about-section">
        <Row className={styles.card_row_col_div}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <div>
              <div className={styles.cardDetailTitle}>WHO WE ARE</div>
              <div className={styles.card_text}>
                O2XC is a thesis-driven Web3 venture firm that helps the next
                generation of entrepreneurs and developers create the next
                generation of tools and applications.
              </div>
            </div>
            <div>
              <div className={styles.cardDetailTitle1}>OUR THESIS</div>
              <div className={styles.card_text}>
                That on-chain based products and services will disrupt, innovate
                and reshape the modern economy, creating the most significant
                wealth shift in history.
              </div>
            </div>
            <div>
              <div className={styles.cardDetailTitle1}>WHY WE EXIST</div>
              <div className={styles.card_text}>
                To continually raise the bar when it comes to improving the way
                in which disruptive on-chain technologies emerge.
              </div>
            </div>

            <div>
              <div className={styles.cardDetailTitle1}>HOW WE DO IT</div>
              <div className={styles.card_text}>
                By creating more transparent, modern and optimized
                infrastructure for financing and funding early staged on-chain
                products and services.
              </div>
            </div>

            <div>
              <div className={styles.cardDetailTitle1}>FIND OUT MORE</div>
              <div className={styles.card_text}>Docs Portal coming soon.</div>
            </div>
          </Col>
          <Col
            xl={12}
            lg={12}
            md={24}
            sm={24}
            xs={24}
            className={styles.card_col_div}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <div className={styles.o2xCardDesign}>
              {/* <div>card</div> */}
              <div className={styles.card_image_div}>
                <Image src="/img/o2xcCardLogo.svg" preview={false} />
              </div>
              <div className={styles.o2x_card_title}>Our Manifesto</div>
              <div className={styles.o2x_card_text}>
                Investing is all about looking into the future. We believe that
                a great place to start is to analyse the current state of the
                market, identify problems and build solutions that better serve
                the market participants. That’s how we operate.
              </div>
              <div className={styles.o2x_card_text}>
                When it comes to building our own infrastructure, nothing
                changes. We’ve published a manifesto that outlines our
                perspective on the current Web3 VC landscape and have outlined
                and proposed the way in which we plan on making the venture
                capital scene more transparent, secure and fairer for all.
              </div>
              <div className={styles.readnow_div}>
                <div className={styles.o2x_card_read_text}>
                  <Link
                    onClick={() => RouteLink()}
                    to="/blog/welcome-to-the-future-of-venture-capital-o2xcs-launch-manifesto"
                  >
                    Read now
                  </Link>
                </div>
                <Image
                  src="/img/cardArrow.svg"
                  style={{ fill: "black" }}
                  preview={false}
                />
              </div>
            </div>
            <div className={styles.CardDesign1}></div>
            <div className={styles.CardDesign2}></div>
            <div className={styles.CardDesign3}></div>
            <div className={styles.CardDesign4}></div>
            <div className={styles.CardDesign5}></div>
          </Col>
        </Row>
      </div>
      {/* <div>
        <div className={styles.portfolioTitle}>PORTFOLIO</div>
        <Row style={{ width: "100%" }}>
          {width < 768 ? (
            <>
              <Col style={{ marginTop: "65px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
              <Col style={{ marginTop: "25px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
              <Col style={{ marginTop: "25px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
              <Col style={{ marginTop: "25px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col style={{ marginTop: "65px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
              <Col style={{ marginTop: "25px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
              <Col style={{ marginTop: "25px", width: "100%" }}>
                <div className={styles.card_first_row}>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                  <div className={styles.portfolio_card}>
                    <Image
                      src="/img/bitso.png"
                      preview={false}
                      className="bitsoImage"
                    />{" "}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div> */}
      {/* blog section */}
      <div className={`${styles.blogSection} Blog-section`}>
        <div className={styles.portfolioTitle}>LATEST INSIGHTS</div>
        {blogListItem && blogListItem.length <= 0 ? (
          <div className={styles.blogErrorText}>
            No latest insights available.
          </div>
        ) : (
          <></>
        )}
        {blogListItem &&
          blogListItem.length > 0 &&
          blogListItem.map((item) => (
            <Link
              onClick={() => RouteLink()}
              to={{
                pathname: `/blog/${item.slug}`,
                state: item,
              }}
            >
              <div className={styles.latest_div_items} key={item.id}>
                <div className={styles.blogDetail_div}>
                  <div className={styles.latest_div_title}>{item.title}</div>
                  <div className={styles.insight_user_calender_div}>
                    <div className={styles.insights_user_div}>
                      <div>
                        <Initial
                          name={item.author}
                          charCount={2}
                          useWords={true}
                          width={25}
                          height={25}
                          fontSize={14}
                          radius={16}
                        />
                      </div>
                      <div className={styles.username_text}>
                        {/* {item.author} */}
                        {truncate(item.author, {
                          length: 13,
                        })}
                      </div>
                    </div>
                    <div className={styles.insight_date_info}>
                      <div>
                        <Image src="./img/calender.svg" preview={false} />
                      </div>
                      <div className={styles.insight_date_info_text}>
                        {FormatDate(item.date)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.aeroImage_div}>
                  <Image src="./img/arrow.svg" preview={false} />
                </div>
              </div>
            </Link>
          ))}
      </div>
      <div className="Faq-section">
        <div className={styles.portfolioTitle}>FREQUENTLY ASKED QUESTIONS</div>
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          className="landing_collapsebar"
          expandIcon={({ isActive }) =>
            isActive ? (
              <MinusOutlined
                className="minusIcon"
                style={{
                  color: "#F04A14",
                }}
              />
            ) : (
              <PlusOutlined
                className="plusIcon"
                style={{
                  color: "#F04A14",
                }}
                // rotate={isActive ? 180 : 0}
              />
            )
          }
          expandIconPosition="right"
        >
          <Panel
            header="How do your pools work?
"
            key="1"
          >
            <p>
              Our pools are effectively on-chain rolling venture funds.
              Investors contribute to our pools on a quarterly subscription
              basis, meaning that each quarter a new subsequent pool is created
              and the preceding pool is closed. These pools remain continuously
              open to new investors. However, much like most venture funds, we
              operate on a private basis only, meaning that contributing is only
              permissible via application or invitation only. Each pool does not
              have its own specific strategy, however you can find out more
              about what type of on-chain projects we’re looking to support in
              our prospectus. All of our pools operate with a 2% management fee
              (to cover operational expenses) and a 20% carry.
            </p>
          </Panel>
          <Panel header="Are there any minimum pool requirements?" key="2">
            <p>
              Yes. A minimum subscription amount of 25,000 USDC per pool will be
              imposed. This means that for each quarterly period an investor
              must contribute at least the minimum subscription amount, although
              they’re free to top up at later stages during that pool’s
              lifecycle. Additionally, investors are obliged to contribute for a
              minimum of four pools (or quarters). Once this initial minimum
              subscription period has ended, the investor is free to unsubscribe
              should they choose to do so, however they will only receive
              exposure to the investments that were made via the pools that they
              contributed to. Each pool will also operate with a soft cap of
              250,000 USDC that must be reached before becoming operational.
            </p>
          </Panel>
          <Panel
            header="What happens if a pool that I’ve contributed to has unallocated capital at the end of its lifecycle?"
            key="3"
          >
            <p>
              If a pool has any unallocated capital at the end of its quarterly
              lifecycle, the capital is automatically rolled into the succeeding
              pool (without any additional management fee charges) with the
              initial contributors receiving proportionally shared exposure to
              the rolled capital.
            </p>
          </Panel>
          <Panel
            header="How do I register my interest in becoming a contributor to your pools?"
            key="4"
          >
            <p>
              If you would like to talk to us about becoming one of our
              investors, reach out to us via this form and one of our managing
              partners will get in touch with you. Please note that becoming an
              investor is only permissible via application or invitation only.
            </p>
          </Panel>
          <Panel header="Do I need to go through a KYC process?" key="5">
            <p>
              Initially yes, although we’re working on implementing some
              zero-knowledge proof technology that will enable us to take
              contributions from users who might want to remain anonymous. We
              will never publicise any of our investor’s personal information or
              publicly attach an identity to a wallet and our users are free to
              remain anonymous on our platform.
            </p>
          </Panel>
          <Panel
            header="Once I’ve been approved, how do I make my contributions?"
            key="6"
          >
            <p>
              Once you’ve formerly been approved as an investor and we’ve gone
              through all KYC procedures you would make your contributions by
              heading over to our native on-chain financing application that can
              be found here. For our initial rollout, subscriptions will need to
              be made manually via our deposit feature. However we’re
              anticipating introducing an entirely automated contribution system
              sometime in the near future. Your contributions are sent to our
              main deposit address before being distributed down to the current
              operational venture pool. All deposit and contract addresses will
              be publicly available on our application and traceable via a
              blockchain explorer.
            </p>
          </Panel>
          <Panel
            header="Can I view previous pool contributions, data, investments and performance?"
            key="7"
          >
            <p>
              All pool contributions, data and investments are verifiable
              on-chain. This means that everything from financing, to
              distribution and then allocation will remain transparent and
              traceable. Our application will showcase this data for each pool
              in an easy-to-use interface. Regarding performance, as we’re a new
              firm and we typically invest in illiquid assets, it would be hard
              to measure performance in real time. We will be publishing regular
              financial and portfolio reports and we would also be more than
              happy to provide evidence of our historic investment performance
              (outside of O2XC) upon request too.
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default Home;
