import React, { useState } from "react";
import styles from "./App.module.css";
import { CloseOutlined } from "@ant-design/icons";
import { scroller } from "react-scroll";
import DrawerComponent from "./components/drawer";
import DefaultLayout from "./components/layout/DefaultLayout/DefaultLayout";

function App() {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const renderone = () => (
    <header>
      <div className={styles.mobile_header_div}>
        <img src="/img/logo.svg" alt="Logoimage" />
        <CloseOutlined
          onClick={() => setDrawerVisible(false)}
          style={{ color: "white" }}
        />
      </div>
    </header>
  );
  //scroll function
  function scrollTo(e) {
    if (e === "about") {
      scroller.scrollTo("about-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70,
      });
      setDrawerVisible(false);
    } else if (e === "Faq") {
      scroller.scrollTo("Faq-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70,
      });
      setDrawerVisible(false);
    } else if (e === "Blog") {
      scroller.scrollTo("Blog-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70,
      });
      setDrawerVisible(false);
    }
    if (e === "contact") {
      scroller.scrollTo("contact-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70,
      });
      setDrawerVisible(false);
    }
  }

  return (
    <div>
      <DefaultLayout setDrawerVisible={setDrawerVisible} scrollTo={scrollTo} />
      <DrawerComponent
        setDrawerVisible={setDrawerVisible}
        isDrawerVisible={isDrawerVisible}
        renderone={renderone}
        styles={styles}
        scrollTo={scrollTo}
      />
    </div>
  );
}

export default App;
