import React from "react";
import styles from "../../../App.module.css";
import { Button, Image } from "antd";
import { Link } from "react-router-dom";

export default function DefaultNavBar({ scrollTo }) {
  return (
    <header>
      <div className={styles.header_div}>
        <Link
          to={{
            pathname: "/",
          }}
        >
          <Image preview={false} src="/img/logo.svg" />
        </Link>
        <div className={styles.middleDiv}>
          <div className={styles.about} onClick={() => scrollTo("about")}>
            ABOUT
          </div>
          {/* <a href="#" className={styles.portfolio}>
              PORTFOLIO
            </a> */}
          <div className={styles.portfolio} onClick={() => scrollTo("Blog")}>
            BLOG
          </div>
          <div className={styles.blog} onClick={() => scrollTo("Faq")}>
            FAQ
          </div>
          <div className={styles.contact} onClick={() => scrollTo("contact")}>
            CONTACT
          </div>
        </div>
        <a
          href="https://fyirw2tfakw.typeform.com/to/UX93QZNl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="invest_btn">INVEST WITH US</Button>
        </a>
      </div>
    </header>
  );
}
