import { Image } from "antd";
import React from "react";
import InlineSVG from "svg-inline-react";
import styles from "../../../App.module.css";
import { menuIcon } from "../../../utils/allSvgs";
import { Link } from "react-router-dom";

export default function MobileNavBar({ setDrawerVisible }) {
  return (
    <header>
      <div className={styles.mobile_header_div}>
        <Link
          to={{
            pathname: "/",
          }}
        >
          <Image preview={false} alt="image" src="/img/logo.svg" />
        </Link>
        <InlineSVG
          src={menuIcon}
          className="barsMenu"
          type="primary"
          onClick={() => setDrawerVisible(true)}
        >
          <span className="barsBtn" />
        </InlineSVG>
      </div>
    </header>
  );
}
