import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import "./font/hellix/Hellix-Regular.ttf";
import "./font/rifton/Rifton Norm.otf";
import { BrowserRouter } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Router>
      <App />
    </Router>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
