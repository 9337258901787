import axios from "axios";
import { BaseURL } from "../../utils/config";

const api = axios.create({
  baseURL: `${BaseURL}/api/v1`,
});

export const getBlogList = async () => {
  try {
    const response = await api
      .get("/blog")
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response.data;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};
export const getBlogDetailList = async (slug) => {
  try {
    const response = await api
      .get(`/blog/${slug}`)
      .then((result) => {
        console.log("result: ", result);
        return result;
      })
      .catch((err) => {
        console.log("err: ", err);
        return false;
      });
    return response.data;
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};
