import { Image, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBlogDetailList } from "../../services/Api/Api";
import styles from "./Blog.module.css";
import { Initial } from "react-initial";
import parse from "html-react-parser";
import RenderIf from "../../utils/RenderIf/renderIf";
import { Link } from "react-router-dom";
import { truncate } from "lodash";

const Blog = () => {
  const location = useLocation();
  const locationState = location.state;

  // let { state } = useLocation();

  const [state, setState] = useState(locationState);

  const [blogListItem, setBlogListItem] = useState([]);

  const dummyArray = [{}, {}, {}, {}];
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogList = async () => {
      const slug =
        location.pathname &&
        location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

      const getBlogListItem = await getBlogDetailList(slug);
      console.log("getBlogListItem: ", getBlogListItem);

      if (getBlogListItem) {
        setState(getBlogListItem?.blog);
        setBlogListItem(getBlogListItem?.latestBlog);
        setIsLoading(false);
      }
    };
    fetchBlogList();
  }, [location.pathname]);

  const FormatDate = (value) => {
    const date = new Date(value);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const RouteLink = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className={styles.headerBlogSection}>
        <div className={styles.gradientSection}>
          <div className={styles.blogHeader}>{state?.title}</div>
          <div>
            <div>
              <div className={styles.header_insight_user_calender_div}>
                <div className={styles.header_insights_user_div}>
                  <div>
                    <Initial
                      name={state?.author}
                      charCount={2}
                      useWords={true}
                      width={25}
                      height={25}
                      fontSize={14}
                      radius={16}
                    />
                  </div>
                  <div className={styles.username_text}>
                    {truncate(state?.author, {
                      length: 13,
                    })}
                  </div>
                </div>
                <div className={styles.header_insight_date_info}>
                  <div>
                    <Image src="../img/calender.svg" preview={false} />
                  </div>
                  <div className={styles.insight_date_info_text}>
                    {FormatDate(state?.date)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.head_blog_heading}>{state?.title}</div> */}
        </div>
        <div
          className={styles.head_blog_text}
          // dangerouslySetInnerHTML={{ __html: state.description }}
        >
          {state?.description ? parse(state?.description) : ""}
        </div>
      </div>
      <div className={`${styles.blogSection} Blog-section`}>
        {blogListItem.length > 0 && (
          <div className={styles.portfolioTitle}>LATEST INSIGHTS</div>
        )}

        <RenderIf isTrue={isLoading}>
          <div>
            {dummyArray?.map((_item, _index) => (
              <div key={_item.id} className={`${styles.voting_card_div} `}>
                <Skeleton active={isLoading} />
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf isTrue={!isLoading}>
          {blogListItem &&
            blogListItem.length > 0 &&
            blogListItem.map((item) => (
              <Link
                onClick={() => RouteLink()}
                to={{
                  pathname: `/blog/${item.slug}`,
                  state: item,
                }}
              >
                <div className={styles.latest_div_items} key={item.id}>
                  <div className={styles.blogDetail_div}>
                    <div className={styles.latest_div_title}>{item.title}</div>
                    <div className={styles.insight_user_calender_div}>
                      <div className={styles.insights_user_div}>
                        <div>
                          <Initial
                            name={item.author}
                            charCount={2}
                            useWords={true}
                            width={25}
                            height={25}
                            fontSize={14}
                            radius={16}
                          />
                        </div>
                        <div className={styles.username_text}>
                          {/* {item?.createdBy?.firstName} */}
                          {truncate(item.createdBy.firstName, {
                            length: 13,
                          })}
                        </div>
                      </div>
                      <div className={styles.insight_date_info}>
                        <div>
                          <Image src="../img/calender.svg" preview={false} />
                        </div>
                        <div className={styles.insight_date_info_text}>
                          {FormatDate(item.date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.aeroImage_div}>
                    <Image src="../img/arrow.svg" preview={false} />
                  </div>
                </div>
              </Link>
            ))}
        </RenderIf>
      </div>
    </div>
  );
};

export default Blog;
