import { Drawer, Image } from "antd";
import React from "react";

export default function DrawerComponent({
  setDrawerVisible,
  isDrawerVisible,
  renderone,
  styles,
  scrollTo,
}) {
  return (
    <Drawer
      title={renderone()}
      placement="left"
      closable={false}
      onClose={() => setDrawerVisible(false)}
      visible={isDrawerVisible}
      className="homeDrawer"
      style={{
        backgroundColor: "#0f0f11",
        display: "flex",
        margin: "auto 0",
      }}
    >
      <div>
        <div>
          <div className={styles.drawerDiv}>
            <div
              className={styles.drawerAbout}
              onClick={() => scrollTo("about")}
            >
              ABOUT
            </div>
            {/* <a href="#" className={styles.drawerPortfolio}>
                PORTFOLIO
              </a> */}
            <div className={styles.drawerBlog} onClick={() => scrollTo("Blog")}>
              BLOG
            </div>
            <div className={styles.drawerBlog} onClick={() => scrollTo("Faq")}>
              FAQ
            </div>
            <div
              className={styles.drawerContact}
              onClick={() => scrollTo("contact")}
            >
              CONTACT
            </div>
          </div>
        </div>

        <div className={styles.copyright_div}>
          <div className={styles.footer_icon_div}>
            <div className={styles.twitterIcon}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/O2XCapital?t=Ba1IFFhDDjEJ0LODbK6M7g&s=31"
              >
                <Image src="./img/icontwitter.svg" preview={false} />
              </a>
            </div>
            <div className={styles.discordIcon}>
              <Image src="./img/icondiscord.svg" preview={false} />
            </div>
          </div>
          <div className={styles.copyright_text}>Copyright @ O2XC 2023</div>
        </div>
      </div>
    </Drawer>
  );
}
