export const downAero = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.6464 31.5535C23.8417 31.7488 24.1583 31.7488 24.3536 31.5535L27.5355 28.3716C27.7308 28.1763 27.7308 27.8597 27.5355 27.6645C27.3403 27.4692 27.0237 27.4692 26.8284 27.6645L24 30.4929L21.1716 27.6645C20.9763 27.4692 20.6597 27.4692 20.4645 27.6645C20.2692 27.8597 20.2692 28.1763 20.4645 28.3716L23.6464 31.5535ZM23.5 16.8L23.5 31.2L24.5 31.2L24.5 16.8L23.5 16.8Z" fill="#F04A14"/>
<circle cx="24" cy="24" r="23.5" stroke="white" stroke-opacity="0.2"/>
</svg>
`;
export const menuIcon = `<svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 6H3" stroke="#F04A14" stroke-width="2" stroke-linecap="square"/>
<path d="M21 12H3" stroke="#F04A14" stroke-width="2" stroke-linecap="square"/>
<path d="M12 18H3" stroke="#F04A14" stroke-width="2" stroke-linecap="square"/>
</svg>
`;