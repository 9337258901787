import React from "react";
import { Layout } from "antd";
import useWindowDimensions from "../../../hook/useWindowDimensions";
import MobileNavBar from "../../NavBar/MobileNavBar";
import DefaultNavBar from "../../NavBar/DefaultNavBar";
import MainScreenRoutes from "../../../router";
import Footer from "../../Footer";
import { Content } from "antd/es/layout/layout";
import styles from "./DefaultLayout.module.css";

export default function DefaultLayout({ setDrawerVisible, scrollTo }) {
  const { width } = useWindowDimensions();

  return (
    <Layout>
      <div style={{ backgroundColor: "#0f0f11" }}>
        {width < 768 && <MobileNavBar setDrawerVisible={setDrawerVisible} />}

        <Content
          className={width > 767 ? styles.bg_main_div : styles.bg_main_div2}
        >
          <div className={styles.bg_container}>
            {width > 768 && <DefaultNavBar scrollTo={scrollTo} />}
            <MainScreenRoutes />
          </div>
        </Content>
        <Footer />
      </div>
    </Layout>
  );
}
